





































import { Vue, Component, Prop } from "vue-property-decorator";
import FancyButton from "@/components/FancyButton.vue";
import Container from "@/components/Container.vue";
import { Auth } from "@/store/modules";

@Component({
  components: {
    FancyButton,
    Container,
  },
  name: 'MyContainers'
})
export default class Home extends Vue {
  containers: any = {};
  logs: any = [];
  isLoading = false;
  loadingError = '';
  headers = [
    { text: 'Type', value: 'type' },
    { text: 'Restaurant', value: 'locationId.name' },
    { text: 'City', value: 'locationId.city' },
    { text: 'Date', value: 'createdAt' }
  ]

  get noContainers() {
    return Object.keys(this.containers).length === 0;
  }

  async refresh() {
    this.isLoading = true;
    try {
      const { containers, logs } = await Auth.myContainers();
      this.containers = containers;
      this.logs = logs;
      this.loadingError = '';
    } catch (err) {
      this.loadingError = err.message;
    }
    this.isLoading = false;
  }

  mounted() {
    this.refresh();
  }
}
